.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .header {
    margin-bottom: 56px;

    h1 {
      text-align: center;
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;

      strong {
        font-weight: 700;
      }
    }
  }

  .footer {
    position: fixed;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e2e6ed;
    color: #8e9ab2;
    font-size: 14px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .btn {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #0072FF;
    border-radius: 4px;
    padding: 12px 16px;
    transition: .3s ease;

    &:hover {
      background-color: #2988FF;
    }

    &:active {
      background-color: #0056C2;
    }
  }
}

.provider {

}
